import React, { useRef, useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled";

import "./SimpleTooltip.css";
import PropTypes from "prop-types";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import TipperPortal from "../Tipper/TipperPortal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;

  top: auto;
  bottom: -5px;
  left: ${({ left }) => left}px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #eeeeee;
`;

const TooltipContainer = styled.div(
  ({ theme, offset: { left, top }, usePortal, noBg }) => `
    position: ${usePortal ? "fixed" : "absolute"};
    left: ${left}px;
    top: ${top - 2}px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.42857143;
    border-radius: 5px;
    text-align: left;
    text-align: start;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    display: inline-block;
    background: ${theme.gray.gray200};
    color: black;
    box-sizing: border-box;
    z-index: 200000;
    ${!noBg && `padding: 6px 12px`};
    };
`
);

export default function SimpleTooltip({
  position,
  label,
  children,
  click,
  usePortal,
  initialOffset,
  useTooltipBody,
  style,
  hideOnScroll,
  onScrollCallback,
  noBg,
  hideArrow,
}) {
  const tooltipRef = useRef(null);
  const attachedElementRef = useRef(null);
  const [show, setShow] = useState(false);

  const [offset, setOffset] = useState({
    top: -10000,
    left: -10000,
  });

  useEffect(() => {
    if (hideOnScroll) {
      document.addEventListener("scroll", () => {
        setShow(false);

        if (onScrollCallback) {
          onScrollCallback();
        }
      });
    }
  }, [hideOnScroll, onScrollCallback]);

  useOnClickOutside(useTooltipBody ? tooltipRef : attachedElementRef, () =>
    setShow(false)
  );

  // for now tooltip with react portal working only with top positioning
  const getPositionFromPortal = useCallback(
    (element, tooltip) => {
      if (usePortal) {
        const pos = element.getBoundingClientRect();
        return {
          left: pos.left - tooltip.offsetWidth / 2 + pos.width / 2,
          top: pos.top - tooltip.offsetHeight,
        };
      }

      return {
        left:
          element.offsetLeft +
          element.offsetWidth / 2 -
          tooltip.offsetWidth / 2,
        top: element.offsetTop - tooltip.offsetHeight,
      };
    },
    [usePortal]
  );

  useEffect(() => {
    const element = attachedElementRef.current;
    const tooltip = tooltipRef.current;

    if (tooltip) {
      switch (position) {
        case "top-right": {
          const { top } = getPositionFromPortal(element, tooltip);
          const table = element.closest("table").getBoundingClientRect();
          const th = element.closest("th");

          setOffset({
            left: table.left - 20,
            top: top + (initialOffset?.top ?? 0),
            arrowLeft: th.offsetLeft + 20 + th.offsetWidth / 2,
          });
          break;
        }
        case "top": {
          const { left, top } = getPositionFromPortal(element, tooltip);
          setOffset({
            left: left + (initialOffset?.left ?? 0),
            top: top + (initialOffset?.top ?? 0),
          });
          break;
        }

        case "left": {
          setOffset({
            left: element.offsetLeft - tooltip.offsetWidth,
            top:
              element.offsetTop +
              element.offsetHeight / 2 -
              tooltip.offsetHeight / 2,
          });
          break;
        }

        case "right": {
          setOffset({
            left: element.offsetLeft + element.offsetWidth,
            top:
              element.offsetTop +
              element.offsetHeight / 2 -
              tooltip.offsetHeight / 2,
          });
          break;
        }

        default: {
          setOffset({
            left:
              element.offsetLeft +
              element.offsetWidth / 2 -
              tooltip.offsetWidth / 2,
            top: element.offsetTop + element.offsetHeight,
          });
        }
      }
    }
  }, [getPositionFromPortal, position, show, initialOffset]);

  const withPortal = (tooltip) => {
    if (usePortal) {
      return <TipperPortal>{tooltip}</TipperPortal>;
    }

    return tooltip;
  };

  const renderArrow = () => {
    if (hideArrow) {
      return null;
    }

    return position === "top-right" ? (
      <Arrow left={offset.arrowLeft} />
    ) : (
      <div className="tooltip-arrow" />
    );
  };

  return (
    <div
      ref={attachedElementRef}
      onMouseOver={() => (!click ? setShow(true) : null)}
      onMouseLeave={() => (!click ? setShow(false) : null)}
      onClick={() => (click ? setShow(!show) : null)}
      style={style}
    >
      {children}
      {show &&
        withPortal(
          <TooltipContainer
            ref={tooltipRef}
            id="tooltip"
            className={position}
            offset={offset}
            usePortal={usePortal}
            onClick={(e) => e.stopPropagation()}
            noBg={noBg}
          >
            {renderArrow()}
            <div className="tooltip-label">{label}</div>
          </TooltipContainer>
        )}
    </div>
  );
}

export function InfoCircleTooltip({ tooltip }) {
  return (
    <SimpleTooltip label={tooltip} usePortal>
      <FontAwesomeIcon icon={["fas", "info-circle"]} />
    </SimpleTooltip>
  );
}

SimpleTooltip.defaultProps = {
  position: "top",
  usePortal: false,
};

SimpleTooltip.propTypes = {
  position: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  click: PropTypes.bool,
  usePortal: PropTypes.bool,
  offset: PropTypes.object,
  useTooltipBody: PropTypes.bool,
  hideOnScroll: PropTypes.bool,
  hideOnScrollCallback: PropTypes.func,
};
