import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";
import {
  lists,
  FILTER_TYPE_MULTI_RANGE,
  FILTER_TYPE_SLIDER,
  FILTER_TYPE_DATE_PRESETS,
} from "../../utils/constants/constants";
import FilterItemDropdown from "./FilterItemDropdown";
import GeneralFilters from "./GeneralFilters";
import DateRangeFilter from "./DateRangeFilter";
import SingleDate from "./SingleDateFilter";
import MultiRangeFilter from "../../UI/RangeSlider/MultiRangeFilter";
import ValueSliderFilter from "../../UI/RangeSlider/ValueSliderFilter";
import { sortMenuFilters } from "../../utils/menuFilters";
import DatePresets from "./MenuFiltersDatePresets/DatePresets";

const Container = styled.div(
  ({ isLoading }) => `
  font-size: 1em;
  ${
    isLoading &&
    `
      pointer-events: none;
      opacity: 0.6;
    `
  }; 
`
);

export default function Filters(props) {
  const {
    dataSourceAccessConfig,
    menuFilterShortList,
    filterType,
    select,
    comparisonModeItem,
    selectPowerEditorModeFilter,
    additionalTheme,
    searchFilterLoading,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const { type, uuid } = filterType ?? {};
  const { includedEmptyValues = {} } = additionalTheme ?? {};

  const whitelistedValues =
    dataSourceAccessConfig.dataSourceFieldValues.byUuid[
      filterType.dataSourceFieldUuid
    ]?.values ?? [];

  const whitelistedValuesFiltered = whitelistedValues.length
    ? filterType.values.filter((value) =>
        whitelistedValues.includes(value.value)
      )
    : filterType.values;

  const elementsSorted = sortMenuFilters(whitelistedValuesFiltered);

  // replace falsy label with "Empty" if allowed in site configuration
  const setEmptyIfAllowed = useCallback(
    (filter) => {
      if (!filter.label && includedEmptyValues[uuid]) {
        return { ...filter, label: "Empty" };
      }

      return filter;
    },
    [includedEmptyValues, uuid]
  );

  // search filters if they are to many
  const filterMap = useCallback(
    ({ label }) => {
      return label
        ?.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    },
    [searchValue]
  );

  const elementsFiltered = useMemo(() => {
    if (filterType.totalCount > elementsSorted.length) {
      return elementsSorted;
    }

    return elementsSorted.map(setEmptyIfAllowed).filter(filterMap);
  }, [elementsSorted, filterMap, filterType.totalCount, setEmptyIfAllowed]);

  // make a test for sorted

  const finalFiltersBeforePagination = useMemo(() => {
    if (
      !(typeof menuFilterShortList === "object" && menuFilterShortList.length)
    ) {
      return elementsFiltered;
    }
    return elementsFiltered.filter((v) =>
      menuFilterShortList.includes(v.value)
    );
  }, [elementsFiltered, menuFilterShortList]);
  const isList = lists.some((l) => l === type);

  const filtersWithSelect = isList ? (
    <FilterItemDropdown
      filters={finalFiltersBeforePagination}
      select={select}
      type={type}
      searchFilterLoading={searchFilterLoading}
      filter={filterType}
    />
  ) : null;

  const multiRangeSlider =
    type === FILTER_TYPE_MULTI_RANGE ? (
      <MultiRangeFilter onChange={select} filter={props.filterType} />
    ) : null;

  // @todo add FILTER_TYPE_SLIDER
  const slider =
    type === FILTER_TYPE_SLIDER ? (
      <ValueSliderFilter
        select={select}
        onChange={select}
        filter={props.filterType}
        value={finalFiltersBeforePagination}
        additionalTheme={additionalTheme}
      />
    ) : null;

  const presets =
    type === FILTER_TYPE_DATE_PRESETS ? (
      <DatePresets
        select={select}
        type={type}
        name={filterType.name}
        defaultValues={filterType.defaultValues}
      />
    ) : null;

  return (
    <Container isLoading={searchFilterLoading && !isList}>
      {filtersWithSelect}
      {multiRangeSlider}
      {slider}
      {presets}

      <DateRangeFilter select={select} type={type} filterType={filterType} />
      <SingleDate select={select} type={type} filterType={filterType} />

      <GeneralFilters
        setSearchValue={setSearchValue}
        select={select}
        finalFiltersBeforePagination={finalFiltersBeforePagination}
        type={type}
        comparisonModeItem={comparisonModeItem}
        selectPowerEditorModeFilter={selectPowerEditorModeFilter}
        filter={filterType}
        searchFilterLoading={searchFilterLoading}
        additionalTheme={additionalTheme}
      />
    </Container>
  );
}
