function parseTemplate(input) {
  if (!input.template) return "--";
  const splitted = input?.template.split(".");
  return Array.isArray(splitted) ? splitted[0] : undefined;
}
export const pageFormatConverter = {
  response: {
    toForm(input) {
      return {
        displayName: input.displayName,
        template: parseTemplate(input),
        ttg: !!input.ttg,
        hasParentTab: !!input.parentName,
        parentName: input.parentName,
        isDefaultForParent: input.isDefaultForParent,
        mobile: input.mobile,
        singleDataSourceMenuFilters: input.singleDataSourceMenuFilters,
      };
    },
  },
  form: {
    toRequest(input, isNew) {
      const { hasParentTab, ...restInput } = input;

      return {
        ...restInput,
        parentName: hasParentTab ? input.parentName : "",
        template: setTemplateName(isNew ? input.displayName : input.template),
      };
    },
  },
};

export function assembleRootPages(pages) {
  const rootPages = [];
  const pagesByParentName = {};

  for (const page of pages) {
    if (!page.parent) {
      rootPages.push(page);
      continue;
    }

    if (!(page.parent in pagesByParentName)) {
      const parentPage = { displayName: page.parent, slug: page.slug };
      rootPages.push(parentPage);
      pagesByParentName[page.parent] = parentPage;

      continue;
    }

    if (page.default) {
      pagesByParentName[page.parent].slug = page.slug;
    }
  }

  return rootPages;
}

/**
 * @todo update this to work correctly (Gary)
 *
 * @param name {string}
 * @returns {string|string}
 */
function setTemplateName(name) {
  return name.trim().split(" ").join("-").concat(".json").toLowerCase() || "--";
}

/**
 * Returns the query uuid for the given page for single data source filters,
 * if single data source filters are enabled for the page.
 *
 * @param page
 * @returns {*|null}
 */
export function getSingleDataSourceFiltersQueryUuidByPage(page) {
  return getFirstVisualizationForPage(page)?.query?.uuid;
}

export function getFirstVisualizationForPage(page) {
  if (!page || !page.singleDataSourceMenuFilters) {
    return null;
  }

  return page?.blocks[0]?.visualizations[0];
}

export function getSelectorForCurrentPageByUuid(pageUuid) {
  return (state) => state.layout.tabs.find((p) => p.uuid === pageUuid);
}
