import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useMemo, useRef } from "react";
import { showToastWithTimeout } from "../../store/actions/message";
import {
  normalizeError,
  handleError,
  REQUEST_CANCELLED,
} from "../../utils/errorHandling";
import axios from "../../axios";
import { chartTypeKeyMap } from "../../utils/constants/chartConstants";
import {
  getSelectorForCurrentPageByUuid,
  getFirstVisualizationForPage,
  getSingleDataSourceFiltersQueryUuidByPage,
} from "../../utils/pages";

export default function (pageUuid) {
  const dispatch = useDispatch();
  const page = useSelector(getSelectorForCurrentPageByUuid(pageUuid));
  const isEnabledForThisPage = page?.singleDataSourceMenuFilters;

  const visualization = getFirstVisualizationForPage(page);
  const queryUuid = getSingleDataSourceFiltersQueryUuidByPage(page);
  const isActiveTable =
    visualization?.settings.type === chartTypeKeyMap.ActiveTable;
  const currentActiveTable = useSelector(
    (state) => state.activeTable.currentTable
  );
  const currentViewUuid = useSelector(
    (state) => state.activeTable.currentViewUuid
  );
  const currentActiveTableUuid = currentActiveTable?.uuid;
  const firstVisualizationActiveTableUuid =
    visualization?.settings.activeTableUuid;
  const activeTableColumns = currentActiveTable?.columns;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const defaultAbortController = new AbortController();

  const isEnabledAndActiveTableReady = useMemo(() => {
    if (!isEnabledForThisPage) {
      return false;
    }

    return !(
      isActiveTable &&
      (!currentActiveTableUuid ||
        currentActiveTableUuid !== firstVisualizationActiveTableUuid)
    );
  }, [
    currentActiveTableUuid,
    firstVisualizationActiveTableUuid,
    isActiveTable,
    isEnabledForThisPage,
  ]);

  useEffect(() => {
    if (isEnabledAndActiveTableReady && !queryUuid) {
      const errorMessage = "Query was not found.";
      showToastWithTimeout(dispatch, errorMessage, "danger");
      setError(
        normalizeError({
          message: errorMessage,
        })
      );
    }
  }, [dispatch, isEnabledAndActiveTableReady, queryUuid]);

  const requestData = useMemo(() => {
    if (!isEnabledForThisPage || !queryUuid) {
      return;
    }

    const requestData = { per_page: 1000 };
    if (activeTableColumns) {
      Object.assign(
        requestData,
        assembleRequestDataForVisibleActiveTableColumns(
          currentActiveTable,
          currentViewUuid
        )
      );
    } else if (isActiveTable) {
      // Wait until the active table columns load.
      return null;
    }
    return JSON.stringify(requestData);
  }, [
    activeTableColumns,
    currentActiveTable,
    currentViewUuid,
    isActiveTable,
    isEnabledForThisPage,
    queryUuid,
  ]);

  const abortControllerRef = useRef(defaultAbortController);

  useEffect(() => {
    if (!requestData) {
      return;
    }
    (async () => {
      setLoading(true);
      try {
        abortControllerRef.current = new AbortController();
        const response = await axios.post(
          `/api/v1/queries/${queryUuid}/get-multiple-filters`,
          requestData,
          {
            signal: abortControllerRef.current.signal,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(response.data.data);
      } catch (e) {
        const normalizedError = handleError(dispatch, e);
        if (normalizedError.type !== REQUEST_CANCELLED) {
          setError(normalizedError);
        }
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      setLoading(false);
      setData(null);
      setError(null);
      abortControllerRef.current.abort();
    };
  }, [dispatch, queryUuid, requestData]);

  return { isEnabledForThisPage, loading, data, error, queryUuid };
}

function assembleRequestDataForVisibleActiveTableColumns(
  currentActiveTable,
  currentViewUuid
) {
  const views = currentActiveTable.views;

  const view = views.find((v) => v.uuid === currentViewUuid) ?? views[0];
  const fieldNamesToUse =
    view?.visibleFields.map((v) => v.field) ??
    currentActiveTable.columns.map((c) => c.name);

  return {
    fields: fieldNamesToUse.map((fieldName) => ({
      name: fieldName,
      fetch: true,
    })),
  };
}
