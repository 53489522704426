import addQuarters from "date-fns/addQuarters";
import { addWeeks, format as dateFormat, subWeeks } from "date-fns";
import startOfQuarter from "date-fns/startOfQuarter";
import endOfQuarter from "date-fns/endOfQuarter";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import addYears from "date-fns/addYears";
import { ourDate } from "../../../utils/formatters/ourFormatter";
import getLatestDate from "./getLatestDate";
import getRange, {
  getDateMinusOneYear,
  getDaysOffsetFromCurrent,
  getDaysOffsetFromPrevYear,
  getToDateType,
} from "./getRange";
import getClampedRange from "./getClampedRange";
import getMenuFiltersString from "./getMenuFilters";
import {
  AGGREGATION_SUM,
  possibleOperators,
} from "../../../utils/constants/constants";
import {
  getFullWeekDateRange,
  isDate,
} from "../../../utils/formatters/dateFormatter";
import { parse } from "qs";
import { isLocalDevServer } from "../../../utils/env";
import { absoluteDate } from "../../../utils/dates/dateFunc";
import { isNumber, last } from "lodash-es";
import { isQueryUuidParameterizedInStore } from "../../../utils/queries";
import { getComparisonModeItem } from "../../../Layout/Block/interruptDashboard";
import { stringifyMinimal, encodeURIMinimal } from "../../../utils/browser";
import { SPECIAL_FILTER_INDEX_DIVIDER } from "./getConditionalFilters";

import { getSingleDataSourceFiltersQueryUuidByPage } from "../../../utils/pages";

let filterCount = 0;
const defaultDateFormat = "MM/dd/yyyy";

export const queryBuilderRuntimeConfig = {
  strict: isLocalDevServer,
};

export function resetFilterCount() {
  filterCount = 0;
}

export function getFilterCount() {
  return filterCount;
}

const setWatchlistFilterName = (prefix, groupIndex, itemIndex, name) => {
  return `${prefix}[${groupIndex}][filters][${itemIndex}][name]=${encodeURIMinimal(
    name
  )}`;
};

const setWatchlistFilterValue = (prefix, groupIndex, itemIndex, value) => {
  return `${prefix}[${groupIndex}][filters][${itemIndex}][values][]=${encodeURIComponent(
    value
  )}`;
};

const setWatchlistFilterOperator = (
  prefix,
  groupIndex,
  itemIndex,
  operator
) => {
  if (!operator) {
    return "";
  }

  return `${prefix}[${groupIndex}][filters][${itemIndex}][operator]=${operator}`;
};

export const setWatchlistFilters = (watchlistFilters) => {
  if (!watchlistFilters) {
    return "";
  }

  const groupType = `&filters[${filterCount}][groupType]=OR`;
  const prefix = `&filters[${filterCount}][filters]`;

  const groupedFilters = Object.values(watchlistFilters).reduce(
    (acc, curr, groupIndex) => {
      acc += `${prefix}[${groupIndex}][groupType]=AND`;

      curr.forEach((item, itemIndex) => {
        acc +=
          setWatchlistFilterName(prefix, groupIndex, itemIndex, item.type) +
          setWatchlistFilterValue(prefix, groupIndex, itemIndex, item.value) +
          setWatchlistFilterOperator(
            prefix,
            groupIndex,
            itemIndex,
            item.operator
          );
      });

      return acc;
    },
    ""
  );

  filterCount++;
  return groupType + groupedFilters;
};

const sanitizedParameterizedValue = (paramValue) => (value) => {
  return value.replace("%24restrict%24", paramValue);
};

function getComparsonFilter(config, activeFilters) {
  return config.find((fft) => activeFilters.find((af) => af.type === fft.from));
}

function getActiveFiltersByComparison(activeFilters, config) {
  return activeFilters.filter((af) => af.type === config.from);
}

export const setParameterizedStringWithValue = (
  activeFiltersByKey,
  filterFromTo,
  menuFilters,
  paramValue,
  comparisonMode,
  term,
  tabUuid
) => {
  let paramIndex = 0;
  const allKeys = Object.keys(activeFiltersByKey);

  const sanitizedActiveFilters = allKeys.reduce((acc, curr) => {
    const newKeys = activeFiltersByKey[curr].map(
      sanitizedParameterizedValue(paramValue)
    );
    return {
      ...acc,
      [filterFromTo?.from === curr ? filterFromTo.to : curr]: newKeys,
    };
  }, {});

  let res = Object.entries(sanitizedActiveFilters).reduce((acc, curr) => {
    const setName = `&parameters[${paramIndex}][name]=${encodeURIMinimal(
      curr[0]
    )}`;
    const operator = possibleOperators.find((po) =>
      curr[1].some((v) => v === po)
    );

    const values = curr[1]
      .filter((v) => v !== operator)
      .reduce((a, c, i, s) => {
        return a + c + (i < s.length - 1 ? "," : "");
      }, "");

    const innerRes = `&parameters[${paramIndex}][type]=value&parameters[${paramIndex}][value]=${encodeURIMinimal(
      values
    )}`;
    paramIndex++;

    return acc + setName + innerRes;
  }, "");

  filterCount = paramIndex;

  const activeFilters = menuFilters
    .map((f) => f.values)
    .flat()
    .filter((v) => v.checked);

  if (comparisonMode && Array.isArray(filterFromTo)) {
    const config = getComparsonFilter(filterFromTo, activeFilters);
    const modeItem = getComparisonModeItem(comparisonMode, tabUuid);

    if (!config || !modeItem) {
      return res;
    }

    const { checked, count } = modeItem;

    const filters = getActiveFiltersByComparison(activeFilters, config);
    const isFinite = Number.isFinite(count);
    const quantity = isFinite ? count : 1;

    if (checked < quantity) {
      return res;
    }

    const indexes = [...Array(isFinite ? quantity : checked).keys()];

    res += `&parameters[${filterCount}][name]=${encodeURIMinimal(
      config.name
    )}&parameters[${filterCount}][value]='${encodeURIMinimal(config.from)}'`;
    filterCount++;

    if (isFinite) {
      res += setFiniteComparisonFilterString(indexes, config, filters);
    } else {
      res += setInfinityComparisonFilterString(indexes, config, filters);
      // if we have infinity selection of one filter then update index only one time at the end
      filterCount++;
    }

    return res;
  }

  if (activeFilters.length && filterFromTo) {
    const { from, to } = filterFromTo;
    const active = activeFilters.find((filter) => filter.type === from);

    if (active) {
      res += `&parameters[${paramIndex}][name]=${encodeURIMinimal(
        to
      )}&parameters[${filterCount}][value]='${encodeURIMinimal(active.value)}'`;
      filterCount++;
    }
  }

  if (term) {
    const name = `&parameters[${paramIndex}][name]=DateAggregation`;
    const value = `&parameters[${paramIndex}][type]=value&parameters[${paramIndex}][value]=${encodeURIMinimal(
      term
    )}`;
    res += name + value;
    filterCount++;
  }

  return res;
};

// Function to replace occurrences of SPECIAL_FILTER_INDEX_DIVIDER in a string with an empty string
function replaceIndexDividerString(str) {
  return (str || "").replace(SPECIAL_FILTER_INDEX_DIVIDER, "");
}

function setFiniteComparisonFilterString(indexes, config, filters) {
  return indexes.reduce((acc, curr) => {
    acc += `&parameters[${filterCount}][name]=${encodeURIMinimal(
      config.values[curr]
    )}&parameters[${filterCount}][type]=value&parameters[${filterCount}][value]=${encodeURIMinimal(
      replaceIndexDividerString(filters[curr].value)
    )}`;
    filterCount++;
    return acc;
  }, "");
}

function setInfinityComparisonFilterString(indexes, config, filters) {
  return indexes.reduce((acc, curr, index) => {
    const comma = index === indexes.length - 1 ? "" : ",";
    acc += `${filters[curr].value}${comma}`;
    return acc;
  }, `&parameters[${filterCount}][name]=${encodeURIMinimal(replaceIndexDividerString(config.values[0]))}&parameters[${filterCount}][type]=value&parameters[${filterCount}][value]=`);
}

export const setFilterString = (
  key,
  isParameterized,
  parameterizedFilterPrefix = ""
) => {
  const useInt = filterCount;
  filterCount++;
  const type = isParameterized ? "parameters" : "filters";
  const name = replaceIndexDividerString(parameterizedFilterPrefix + key);

  return `&${type}[${useInt}][name]=${encodeURIMinimal(name)}`;
};

export const setSubQueryString = (name, subQuery) => {
  const useInt = filterCount;
  filterCount++;

  const prefix = `&filters[${useInt}]`;
  const sub = `[subQueryValues]`;

  const mainQueryName = `${prefix}[name]=${encodeURIMinimal(name)}`;

  const subQueryName = `${prefix}${sub}[name]=${encodeURIMinimal(
    subQuery.type
  )}`;

  const subQueryOperator = subQuery.operator
    ? `${prefix}${sub}[operator]=${subQuery.operator}`
    : "";

  const subQueryValue = `${prefix}${sub}[values][0]=${encodeURIMinimal(
    subQuery.value
  )}`;

  const subQueryAggregation = `${prefix}${sub}[aggregation][type]=${AGGREGATION_SUM}`;

  return (
    mainQueryName +
    subQueryName +
    subQueryOperator +
    subQueryValue +
    subQueryAggregation
  );
};

export function assembleFilterStringsFromArray(filtersArray) {
  return filtersArray.length
    ? `&${stringifyMinimal({ filters: filtersArray })}`
    : "";
}

/**
 * Changes the indexes of the filters to start from a different index.
 * This is in order to prepend filters that have been assembled via objects
 * and qs.stringify().
 */
export function renumberFiltersQueryString(
  queryString,
  filtersQueryObjectArrayLength
) {
  if (!filtersQueryObjectArrayLength) {
    return queryString;
  }

  filterCount = filtersQueryObjectArrayLength;

  if (!queryString) {
    return queryString;
  }

  const { filters, ...ret } = parse(queryString.trimStart("&"));
  if (!filters) {
    return queryString;
  }
  const object = {};
  for (const [filterIndex, filter] of Object.entries(filters)) {
    const key = Number(filterIndex);
    const newKey = key + filtersQueryObjectArrayLength;
    filterCount = Math.max(filterCount, newKey);
    object[newKey] = filter;
  }
  filterCount++;
  ret.filters = object;

  return "&" + stringifyMinimal(ret);
}

function wrapEmptyWithQuotes(val) {
  return val === "" ? `"${val}"` : val;
}

export const addFilterValue = (valueProp, isParameterized) => {
  const value = isParameterized ? `'${valueProp}'` : valueProp;
  const key = isParameterized ? "parameters" : "filters";

  let valueName = isParameterized ? "[value]" : "[values][]";
  const hasDataSourceAlias = (value + "").startsWith("ds:");
  let val = value;

  if (possibleOperators.includes(value)) {
    valueName = "[operator]";
    val = wrapEmptyWithQuotes(val);
  } else if (hasDataSourceAlias) {
    valueName = "[ds]";
    val = value.replace("ds:", "");
  } else if (valueProp === "aggregated") {
    valueName = "[aggregated]";
    val = 1; // true
  }

  return `&${key}[${filterCount - 1}]${valueName}=${encodeURIMinimal(val)}`;
};

// Only for dates
const getParameterizedValue = (value) => {
  return `&parameters[${filterCount - 1}][value]='${encodeURIMinimal(value)}'`;
};

const getDateRangeOverrideFilters = (
  term,
  from,
  to,
  dateKey,
  isParameterized
) => {
  if (term === "weekly") {
    // @todo research this date -- is it arbitrary?
    const dFrom = dateFormat(
      subWeeks(new Date("2017-08-01"), from),
      "yyyy-MM-dd"
    );
    const dTo = dateFormat(addWeeks(new Date("2017-08-01"), to), "yyyy-MM-dd");

    return (
      setFilterString(dateKey, isParameterized) +
      addFilterValue(dFrom, isParameterized) +
      addFilterValue(dTo, isParameterized)
    );
  }
  return "";
};

export const makeFixedRange = ({ dateKey, rangeType, offset = 0 }) => {
  const fns = {
    quarter: [startOfQuarter, endOfQuarter, addQuarters],
    year: [startOfYear, endOfYear, addYears],
  };

  const getRange = (start, end, add) => {
    return {
      start: ourDate(start(add(new Date(), offset))),
      end: ourDate(end(add(new Date(), offset))),
    };
  };

  const { start, end } = getRange(...fns[rangeType]);
  return setFilterString(dateKey) + addFilterValue(start) + addFilterValue(end);
};

const validate = (query) => {
  if (!query) {
    throw new Error("Query not passed");
  }

  if (
    query.filters &&
    query.filters.find(
      (f) =>
        !f.type ||
        (!f.subQueryValues &&
          !f.value &&
          f.value !== null &&
          f.value !== "" &&
          f.value !== 0)
    )
  ) {
    throw new Error(errorString("filter", query.id, "{type, value}"));
  }
};

const errorString = (type, id, format) => {
  return `Invalid ${type} configuration in chartId ${id} format ${{
    format,
  }} required`;
};

const getBooleanFiltersString = (
  booleanFilters,
  isParameterized,
  parameterizedFilterPrefix
) => {
  const booleanFilterString = booleanFilters.reduce((acc, curr) => {
    return curr.checked
      ? acc +
          setFilterString(
            curr.key,
            isParameterized,
            parameterizedFilterPrefix
          ) +
          addFilterValue(curr.value ? 1 : 0, isParameterized)
      : acc;
  }, "");

  return booleanFilterString;
};

export const getParameterizedDateFilters = (keys, startDate, endDate) => {
  let str = "";

  if (startDate && keys) {
    str += setFilterString(keys.start, true) + getParameterizedValue(startDate);
  }

  if (endDate && keys) {
    str += setFilterString(keys.end, true) + getParameterizedValue(endDate);
  }

  return str;
};

/**
 * @param {Object} query
 * @param {"month"|"week"|undefined} query.maxDateType - For limiting the date range; unit to limit by.
 * @param {number|undefined} query.maxDateCount - For limiting the date range; amount of unit to limit by.
 * @param {boolean|undefined} query.hasFuture - For limiting the date range; Unless this property is TRUE, if the
 *  end date is in the future, the current date will be used instead for the purposes of limiting the date range.
 * @param {boolean|undefined} query.dateStyle - If TRUE, date filter values should be in Y-m-d format without including time.
 * @param dateFilters
 * @param disableDateFiltering
 * @returns {string|*}
 */
const getDateFiltersString = (query, dateFilters, disableDateFiltering) => {
  const {
    allDates,
    includeFullWeek,
    ignoreGlobals,
    dateEndOnly,
    maxDateCount,
    maxDateType,
    hasFuture,
    dateStyle,
    dateEndField,
    dateKey,
    dateRangeOverride,
    dateTerm,
    dateFrom,
    dateTo,
    isParameterized,
    dateKeys,
    endDateMinusOneYear,
    daysOffsetFromCurrent,
    daysOffsetFromPrevYear,
    toDateType,
  } = query;

  // disableDateFiltering based on hideOnPages parameter (not all page has dateFilters)
  if (
    allDates ||
    disableDateFiltering ||
    endDateMinusOneYear ||
    daysOffsetFromCurrent ||
    daysOffsetFromPrevYear ||
    toDateType
  ) {
    return "";
  }

  const { start, end } = getFullWeekDateRange(includeFullWeek, dateFilters);

  if (!start?.value || !end?.value) {
    return "";
  }

  if (isParameterized) {
    return getParameterizedDateFilters(dateKeys, start?.value, end?.value);
  }

  const clampedStart = maxDateCount
    ? {
        ...start,
        value: getClampedRange(
          start.value,
          end.value,
          maxDateType,
          maxDateCount,
          hasFuture
        )?.start,
      }
    : start;

  const startDate = dateStyle
    ? { ...clampedStart, value: clampedStart.value.substring(0, 10) }
    : clampedStart;

  const endDate = dateStyle
    ? { ...end, value: end.value.substring(0, 10) }
    : end;

  if (ignoreGlobals) {
    return makeFixedRange(query);
  } else if (dateEndOnly && endDate) {
    return (
      setFilterString(dateEndField) +
      addFilterValue(dateFormat(new Date(endDate.value), "yyyy QQQ"))
    );
  } else if (startDate) {
    const key = dateKey || startDate.type;
    if (key === undefined) {
      throwErrorIfStrict(
        `You need a "dateKey" property to define which field the global date filters should filter.`
      );
    }
    return (
      setFilterString(key) +
      addFilterValue(query.startDate || startDate.value) +
      addFilterValue(query.endDate || endDate.value)
    );
  } else if (dateRangeOverride) {
    return getDateRangeOverrideFilters(dateTerm, dateFrom, dateTo, dateKey);
  }

  return "";
};

export const getQuarterlies = (query, dateFilters) => {
  const { quarterRange, quarterRangeKey, isParameterized, dateFormatting } =
    query;

  if (quarterRange) {
    return (
      setFilterString(quarterRangeKey, isParameterized) +
      getRange(dateFilters, "quarterly", dateFormatting).reduce(
        (a, c) => a + addFilterValue(c, isParameterized),
        ""
      )
    );
  }

  return "";
};

const getMonthlies = (query, dateFilters) => {
  const { monthRange, monthRangeKey, isParameterized } = query;
  if (monthRange) {
    return (
      setFilterString(monthRangeKey, isParameterized) +
      getRange(dateFilters, "monthly").reduce(
        (a, c) => a + addFilterValue(c, isParameterized),
        ""
      )
    );
  }

  return "";
};

const getLatestDateString = (query) => {
  const { latestDate, isParameterized } = query;
  if (latestDate) {
    return (
      setFilterString(latestDate.field, isParameterized) +
      addFilterValue(getLatestDate(latestDate.term), isParameterized)
    );
  }

  return "";
};

const dateRangeToString = (start, end) => {
  const from = dateFormat(absoluteDate(start.value), defaultDateFormat);
  const to = dateFormat(absoluteDate(end.value), defaultDateFormat);

  return encodeURIComponent(`${from} - ${to}`);
};

const getRangeFromMenuFilter = (menuFilters, name) => {
  const values =
    menuFilters.find((filter) => filter.name === name)?.values ?? [];
  const [start = {}, end = {}] = values;

  if (!isDate(start.value) || !isDate(end.value)) {
    return "";
  }

  return dateRangeToString(start, end);
};

const getRangeFromDates = (dateFilters) => {
  const { start, end } = dateFilters;

  if (!isDate(start.value) || !isDate(end.value)) {
    return "";
  }

  return dateRangeToString(start, end);
};

const setOverrideDisplayName = (mapping, menuFilters, dateFilters) => {
  const { overrideByMenuFilter, overrideByDateFilter } = mapping;

  if (overrideByMenuFilter) {
    return getRangeFromMenuFilter(menuFilters, overrideByMenuFilter);
  }

  if (overrideByDateFilter) {
    return getRangeFromDates(dateFilters);
  }

  return "";
};

const splitByGroupFn = (groupUuid, groups) => {
  if (groupUuid && groups) {
    return groups.find((group) => group.uuid === groupUuid)?.name ?? "";
  }

  return "";
};

const splitByFilterFn = (name, menuFilters) => {
  const filter = menuFilters.find((filter) => filter.name === name);
  if (filter) {
    return filter.values.find((val) => val.checked)?.value;
  }

  return "";
};

// parameterized queries do not support splitBy
// solution: replace displayName by splitBy value like we doing for mapping in overrides
function setParameterizedSplitBy(splitBy, menuFilters, splitByGroup) {
  const ret = {};
  // we have 2 types of split by for parameterized queries
  const { name, type, label } = splitBy;
  const { groupUuid, groups } = splitByGroup || {};

  if (type) {
    ret.type = type;
  }

  const fromGroup = label === "[FROM_GROUP]";

  const displayName = fromGroup
    ? splitByGroupFn(groupUuid, groups) // 2) split by from user group
    : splitByFilterFn(name, menuFilters); // 1) split by from active filter

  if (displayName) {
    ret.displayName = displayName;
  }

  return ret;
}

function getOverrideFieldMapping(
  curr,
  menuFilters,
  dateFilters,
  isParameterized,
  splitByGroup
) {
  const mapping = {};

  if (curr.splitBy && isParameterized) {
    return setParameterizedSplitBy(curr.splitBy, menuFilters, splitByGroup);
  }

  if (curr.mapping) {
    const { type, displayName, hideOnTables } = curr.mapping;
    if (type) {
      mapping.type = type;
    }
    if (hideOnTables) {
      mapping.hideOnTables = hideOnTables;
    }

    if (displayName) {
      mapping.displayName =
        setOverrideDisplayName(curr.mapping, menuFilters, dateFilters) ||
        encodeURIComponent(displayName);
    }
  }

  return mapping;
}

function applyOverrideOps(override, curr) {
  if (curr.ops) {
    override.ops = {
      type: curr.ops.type,
      alias: curr.ops.alias,
    };

    if (curr.ops.aggregation) {
      override.ops.aggregation = {
        type: curr.ops.aggregation,
      };
    }

    if (isNumber(curr.ops.value)) {
      override.ops.value = curr.ops.value;
    } else if (curr.ops.fields) {
      const field = last(curr.ops.fields);
      const fieldObject = {
        name: field.name,
        aggregation: field.aggregation && {
          type: field.aggregation,
        },
      };
      override.ops.fields = [fieldObject];
    }
  }
}

const terms = {
  daily: "10",
  weekly: "9",
  monthly: "8",
  quarterly: "7",
  yearly: "6",
};

const termFormats = {
  daily: "yyyy-MM-dd",
  weekly: "date-week",
  monthly: "monthly",
  quarterly: "string",
  yearly: "yyyy",
};

const getDateTerm = (
  term,
  ignoreDateTerm,
  allFieldsOverride,
  dateKey,
  dateFilters,
  index
) => {
  if (term && !ignoreDateTerm && !allFieldsOverride) {
    return `&overrides[fields][${index}][name]=${encodeURIMinimal(
      dateKey || dateFilters.start.type
    )}&overrides[fields][${index}][aggregation][type]=${encodeURIMinimal(
      terms[term]
    )}&overrides[fields][${index}][mapping][type]=${encodeURIMinimal(
      termFormats[term]
    )}`;
  }

  return "";
};

function getSplitByStringValue(name, filter) {
  if (!name) {
    return;
  }

  const { value } = filter?.values.find((mf) => mf.checked) ?? {};
  return value || name;
}

function applyOverrideSplitBy(
  override,
  splitBy,
  menuFilters,
  paramValue,
  { groups = [], groupUuid },
  isParameterized
) {
  if (!splitBy || isParameterized) {
    return;
  }

  const { name, value, alias, label } = splitBy;
  const filter =
    value === "[DYNAMIC]" ? menuFilters.find((mf) => mf.name === name) : null;

  let paramValueOverride = paramValue;
  let labelOverride = null;

  // dynamic label for line bar chart based on group name
  if (label === "[FROM_GROUP]") {
    const { name } = groups.find((group) => group.uuid === groupUuid) ?? {};
    paramValueOverride = name;
    labelOverride = getSplitByStringValue(name, filter);
  }

  override.aggregation.splitBy = {
    field: {
      name,
      alias,
      label: labelOverride || getSplitByStringValue(label, filter),
      value: getSplitByStringValue(
        value.replace("*restrict*", paramValueOverride),
        filter
      ),
    },
  };
}

const getOverridesAndDateTerms = (
  query,
  dateFilters,
  term,
  allFieldsOverride,
  menuFilters,
  paramValue,
  splitByGroup
) => {
  const { dateKey, overrides, ignoreDateTerm, isParameterized } = query;

  let overridesInt = 0;
  let overrideString = "";

  if (overrides?.length && !allFieldsOverride) {
    const fields = overrides.map((curr, index) => {
      const overrideObject = {
        name: curr.name,
        aggregation: {
          type: curr.aggregation,
        },
      };
      overrideObject.mapping = getOverrideFieldMapping(
        curr,
        menuFilters,
        dateFilters,
        isParameterized,
        splitByGroup
      );

      applyOverrideSplitBy(
        overrideObject,
        curr.splitBy,
        menuFilters,
        paramValue,
        splitByGroup,
        isParameterized
      );

      overrideObject.alias = curr.alias;
      overrideObject.ds = curr.ds;
      applyOverrideOps(overrideObject, curr, index); // math sql operations

      return overrideObject;
    });

    overridesInt = fields.length;
    overrideString += "&" + stringifyMinimal({ overrides: { fields } });
  }

  const dateTerms = getDateTerm(
    term,
    ignoreDateTerm,
    allFieldsOverride,
    dateKey,
    dateFilters,
    overridesInt
  );

  return {
    overrideString,
    dateTerms,
  };
};

const setDynamicFilterOverrideBasedOnDates = (query, dateFilterType) => {
  const dynamicFilterOverride = {};
  const existingFilters = query.filters || [];

  if (query.dateFilterOverrides && dateFilterType) {
    dynamicFilterOverride.type = query.dateFilterOverrides.type;
    dynamicFilterOverride.value = query.dateFilterOverrides[dateFilterType];
  }

  return Object.keys(dynamicFilterOverride).length
    ? [...existingFilters, dynamicFilterOverride]
    : existingFilters;
};

export default function queryBuilder(
  query,
  getState,
  allFieldsOverride = false,
  format = "chart",
  options = {},
  page
) {
  const { forHeaderBlocks, editorMode } = options;
  validate(query);
  filterCount = 0;

  // Object that will get converted into form data. Any new property should go
  // in this. Also, existing query parameters should eventually get migrated
  // over into this query object rather than assembling query strings directly.
  const queryObj = {};

  if (
    !("isParameterized" in query) &&
    isQueryUuidParameterizedInStore(query.queryId, getState())
  ) {
    query = { ...query, isParameterized: true };
  }

  const {
    booleanFilters = [],
    dateFilters,
    term,
    activeTab,
    dateFiltersConfig,
    dataSourceAccessConfig,
    comparisonMode,
    dateFilterType,
    filtersUpdateHeaderKPIs,
  } = getState().layout;
  const tab = page ?? activeTab;
  const menuFilters = getMenuFiltersFromState(getState(), tab?.uuid);

  const userManagement = getState().userManagement;
  const auth = getState().auth;

  const { groups } = userManagement ?? { groups: [] };
  const { group: groupUuid } = auth ?? {};

  // when we save menu filters they are checked in the store
  // we ignoring it only when selecting filter from left menu
  // but not when user saved filters
  function ignoreSavedFilters(filter) {
    if (forHeaderBlocks && !filtersUpdateHeaderKPIs) {
      return filter.values.every((value) => !value.checked);
    }
    return true;
  }

  const filterString = getMenuFiltersString(
    {
      ...query,
      filters: setDynamicFilterOverrideBasedOnDates(query, dateFilterType),
    },
    menuFilters.filter(ignoreSavedFilters),
    tab,
    dataSourceAccessConfig?.paramValue,
    comparisonMode,
    term
  );

  // eslint-disable-next-line
  const booleanFilterString = getBooleanFiltersString(
    booleanFilters
      .filter((f) => !query.ignoreMenuFilters?.includes(f.key))
      .filter((f) => !f.hideOnPages?.includes(activeTab?.uuid)),
    query.isParameterized,
    query.parameterizedFilterPrefix
  );

  const disableDateFiltering = dateFiltersConfig?.hideOnPages?.includes(
    activeTab?.uuid
  );

  const dateString = getDateFiltersString(
    query,
    dateFilters,
    disableDateFiltering
  );

  if (!!query.maxDateCount + !!query.quarterRange + !!query.monthRange > 1) {
    throw new Error(
      "Only at most one of maxDateCount, quarterRange, or monthRange can be used."
    );
  }

  const quarterlies = getQuarterlies(query, dateFilters);
  const monthlies = getMonthlies(query, dateFilters);
  const latestDate = getLatestDateString(query);
  const endDateMinusOneYear = getDateMinusOneYear(query, dateFilters);
  const daysOffsetFromCurrent = getDaysOffsetFromCurrent(query);
  const daysOffsetFromPrevYear = getDaysOffsetFromPrevYear(query);
  const toDateType = getToDateType(query);

  const orderString = query.orders
    ? query.orders.reduce(
        (acc, order) =>
          acc +
          `&order[${encodeURIMinimal(order.name)}]=${encodeURIMinimal(
            order.sort
          )}`,
        ""
      )
    : "";
  const { overrideString, dateTerms } = getOverridesAndDateTerms(
    query,
    dateFilters,
    term,
    allFieldsOverride,
    menuFilters,
    dataSourceAccessConfig?.paramValue,
    { groups, groupUuid }
  );
  const allRows = query.withRowsCount ? "&withRowsCount=1" : "";
  const overridesOther = query.asOf ? "&overrides[other][asOf]=today" : "";

  const pageString = setPageString(query);

  queryObj.includeTotalsRow = query.includeTotalsRow ? 1 : undefined;
  queryObj.format = format;
  if (editorMode) {
    queryObj.editorMode = 1;
  }

  return (
    filterString +
    booleanFilterString +
    orderString +
    overrideString +
    dateString +
    quarterlies +
    monthlies +
    latestDate +
    endDateMinusOneYear +
    daysOffsetFromCurrent +
    daysOffsetFromPrevYear +
    toDateType +
    dateTerms +
    allRows +
    overridesOther +
    pageString +
    "&" +
    stringifyMinimal(queryObj)
  );
}

const setPageString = (query) => {
  const {
    limit,
    page = 1,
    isParameterized,
    connectWithQueryParameter,
    displayRows,
    enablePagination,
  } = query;

  let quantity = limit;

  if (!limit && connectWithQueryParameter) {
    quantity = displayRows;
  }

  if (!quantity) {
    return "";
  }

  if (isParameterized && enablePagination) {
    return `&parameters[${filterCount}][name]=perPage&parameters[${filterCount}][value]=${encodeURIMinimal(
      quantity
    )}&parameters[${filterCount + 1}][name]=page&parameters[${
      filterCount + 1
    }][value]=${encodeURIMinimal(page)}`;
  }

  const pagePart = enablePagination ? `&page=${encodeURIMinimal(page)}` : "";
  return `&perPage=${encodeURIMinimal(limit)}${pagePart}`;
};

function throwErrorIfStrict(message) {
  if (queryBuilderRuntimeConfig.strict) {
    throw new QueryBuilderError(message);
  }
}

export class QueryBuilderError extends Error {}

/**
 * Gets the menu filters to be used for the given page.
 *
 * @param state
 * @param page
 * @returns {*[]}
 */
export function getMenuFiltersFromState(state, page) {
  const singleDataSourceFiltersQueryUuid =
    getSingleDataSourceFiltersQueryUuidByPage(page);
  if (!singleDataSourceFiltersQueryUuid) {
    return state.layout.menuFilters ?? [];
  }

  const ret = [];
  const singleQueryFilters =
    state.layout.singleQueryFiltersByQueryUuid[
      singleDataSourceFiltersQueryUuid
    ];
  if (!singleQueryFilters) {
    return [];
  }
  for (const [fieldName, filter] of Object.entries(
    singleQueryFilters.fieldsByName
  )) {
    if (filter.selectedValues.length) {
      const values = [];
      for (const value of filter.selectedValues) {
        values.push({ key: fieldName, type: fieldName, value, checked: true });
      }
      ret.push({ name: fieldName, values });
    }
  }

  return ret;
}

/**
 * Gets the menu filters to be used for the given visualization.
 * May give you single data source filters if the visualization is on such a
 * page.
 *
 * @param state
 * @param visualizationUuid
 * @returns {*[]}
 */
export function getMenuFiltersFromStateByVisualizationUuid(
  state,
  visualizationUuid
) {
  for (const page of state.layout.tabs ?? []) {
    for (const block of page.blocks) {
      for (const visualization of block.visualizations) {
        if (visualization.uuid === visualizationUuid) {
          return getMenuFiltersFromState(state, page);
        }
      }
    }
  }
  return getMenuFiltersFromState(state, null);
}
