import React, { useMemo } from "react";
import MenuFilter from "./MenuFilter";
import {
  FILTER_TYPE_CHECKBOX,
  emptyArray,
} from "../../utils/constants/constants";
import { useSelector } from "react-redux";

export default function SingleDataSourceMenuFiltersItem({
  item,
  queryUuid,
  additionalTheme,
  dataSourceAccessConfig,
  select,
}) {
  const selectedValues = useSelector(
    (state) =>
      state.layout.singleQueryFiltersByQueryUuid[queryUuid]?.fieldsByName[
        item.fieldName
      ]?.selectedValues ?? emptyArray
  );
  const filterType = useMemo(() => {
    const values = [];
    for (const value of item.values) {
      values.push({
        key: item.fieldName,
        type: item.fieldName,
        value,
        label: value,
        checked: selectedValues.includes(value),
        singleQueryFiltersUuid: queryUuid,
      });
    }

    return {
      type: FILTER_TYPE_CHECKBOX,
      displayName: item.fieldDisplayName,
      values,
    };
  }, [
    item.fieldDisplayName,
    item.fieldName,
    item.values,
    queryUuid,
    selectedValues,
  ]);

  return (
    <MenuFilter
      filterType={filterType}
      additionalTheme={additionalTheme}
      dataSourceAccessConfig={dataSourceAccessConfig}
      select={select}
    />
  );
}
